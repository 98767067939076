const config = {
  url: '/event-types',
  title: 'Типы событий',
  endpoint: '/admin-search?filter=event_types',
  type: 'table',
  getUrl: id => `/event-type/${id}`,
  getApiUrl: id => `/event-types/${id}`,
  search: true,
  key: 'event-types',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'name',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.name,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },

    },
  ],
  filters: [],
}

export default config
