import moment from "moment"

const config = {
  url: '/festival/:id',
  getTitle: id => id === 'create' ? 'Создание фестиваля' : 'Редактирование фестиваля',
  getApiUrl: id => id === 'create' ? '/festivals' : `/festivals/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'name',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: true,
          },
          {
            key: 'topic',
            title: 'Тема',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'date_s',
            title: 'Дата начала',
            component: 'Date',
            placeholder: 'Введите',
            showTime: false,
            format: 'YYYY-MM-DD',
            style: { width: '100%' },
            defaultValue: moment().format('YYYY-MM-DD'),
            getValue: data => moment(data.date_s, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            required: true,
          },
          {
            key: 'date_e',
            title: 'Дата окончания',
            component: 'Date',
            placeholder: 'Введите',
            showTime: false,
            format: 'YYYY-MM-DD',
            style: { width: '100%' },
            defaultValue: moment().format('YYYY-MM-DD'),
            getValue: data => moment(data.date_e, 'YYYY-MM-DD').format('YYYY-MM-DD'),
            required: true,
          },
          {
            key: 'is_active',
            title: 'Активен',
            component: 'Switch',
            defaultValue: 0,
          },
        ],
        right: [
          {
            key: 'logo_storage_id',
            title: 'Логотип (svg, png)',
            component: 'File',
            multiple: false,
            maxCount: 1,
            keyName: 'logo_storage_id',
            keyList: 'logo_storage',
            allowTypes: ['svg', 'png'],
          },
        ],
        both: [
          {
            key: 'description',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [
          {
            key: 'name_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'topic_en',
            title: 'Тема (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
        ],
        right: [],
        both: [
          {
            key: 'description_en',
            title: 'Описание (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
  ]
}

export default config
