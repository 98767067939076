const config = {
  url: '/person/:id',
  getTitle: id => id === 'create' ? 'Создание персоны' : 'Редактирование персоны',
  getApiUrl: id => id === 'create' ? '/persons' : `/persons/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'surname',
            title: 'Фамилия',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'name',
            title: 'Имя',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'patronymic',
            title: 'Отчество',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'science_organization_id',
            title: 'Научная организация',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=science_organizations&sort[field]=title&sort[type]=asc&limit=100',
            disableFilterByValue: true,
            filter: 'science_organizations',
            placeholder: 'Выберите организацию',
            search: true,
            mode: 'single',
            getOptionValue: data => data.title,
            required: false,
            relatedCard: '/science-organization/:id',
          },
          {
            key: 'post',
            title: 'Должность',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'rewards',
            title: 'Звания, награды',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'science_interests',
            title: 'Научные интересы',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
        ],
        right: [
          {
            key: 'image_storage_id',
            title: 'Изображение (jpg)',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
          },
          {
            key: 'research_ids',
            title: 'Исследования',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=researches&sort[field]=short_title&sort[type]=asc&limit=50',
            filter: 'researches',
            placeholder: 'Выберите',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.short_title,
            required: false,
            //relatedCard: '/research/:id',
          },
          {
            key: 'publication_ids',
            title: 'Публикации',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=publications&sort[field]=short_title&sort[type]=asc&limit=50',
            filter: 'publications',
            placeholder: 'Выберите',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.short_title,
            required: false,
            //relatedCard: '/publication/:id',
          },
          {
            key: 'event_ids',
            title: 'События',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=events&sort[field]=title&sort[type]=asc&limit=50',
            filter: 'events',
            placeholder: 'Выберите',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.title,
            required: false,
            relatedCard: '/event/:id',
          },
          {
            key: 'action_ids',
            title: 'Мероприятия',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=actions&sort[field]=title&sort[type]=asc&limit=50',
            filter: 'actions',
            placeholder: 'Выберите',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.title,
            required: false,
            relatedCard: '/action/:id',
          },
          {
            key: 'tag_ids',
            title: 'Теги',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=tags&sort[field]=name&sort[type]=asc&limit=200',
            disableFilterByValue: true,
            filter: 'tags',
            placeholder: 'Выберите теги',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/tag/:id',
          },
          {
            key: 'create_page',
            title: 'Создать страницу',
            component: 'Switch',
            defaultValue: 0,
          },
        ],
        both: [
          {
            key: 'short_description',
            title: 'Короткое описание',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'main_description',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [
          {
            key: 'surname_en',
            title: 'Фамилия (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'name_en',
            title: 'Имя (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'patronymic_en',
            title: 'Отчество (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'post_en',
            title: 'Должность (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'rewards_en',
            title: 'Звания, награды (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'science_interests_en',
            title: 'Научные интересы (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
        ],
        right: [],
        both: [
          {
            key: 'short_description_en',
            title: 'Короткое описание (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'main_description_en',
            title: 'Описание (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
  ]
}

export default config
