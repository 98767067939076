import produce from 'immer'
import {
  INIT_CARD,
  SET_FIELD,
  CREATE_REQUEST,
  CREATE_SUCCESS,
  GET_REQUEST,
  GET_SUCCESS,
  SET_VALIDATION,
  UPDATE_REQUEST,
  UPDATE_SUCCESS,
  UPDATE_MODAL,
  RESET_NEW_ID,
} from '../actions/SimpleCardActions'

const initialState = {
  isOpen: false,
  openId: null,
  isLoading: false,
  fields: {},
  url: '',
  errorFields: [],
  newId: null,
}

export default function state(state = initialState, action) {
  switch (action.type) {
    case UPDATE_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.isOpen = false
        draftState.newId = action.payload.data.id
      })
    case UPDATE_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case SET_VALIDATION:
      return produce(state, draftState => {
        draftState.errorFields = action.payload.errorFields
      })
    case GET_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
        draftState.fields = initialState.fields
        draftState.url = action.payload.cardUrl
      })
    case GET_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.fields = action.payload.data
      })
    case CREATE_REQUEST:
      return produce(state, draftState => {
        draftState.isLoading = true
      })
    case CREATE_SUCCESS:
      return produce(state, draftState => {
        draftState.isLoading = false
        draftState.fields = action.payload.data
        draftState.isOpen = false
        draftState.newId = action.payload.data.id
      })
    case SET_FIELD:
      return produce(state, draftState => {
        draftState.fields[action.payload.field] = action.payload.value
      })
    case INIT_CARD:
      return produce(state, draftState => {
        draftState.fields = action.payload.fields
        draftState.url = action.payload.url
        draftState.newId = null
        draftState.errorFields = []
      })
    case UPDATE_MODAL:
      return produce(state, draftState => {
        draftState.isOpen = action.payload.isOpen
        draftState.openId = action.payload.openId
      })
    case RESET_NEW_ID:
      return produce(state, draftState => {
        draftState.newId = null
      })
    default:
      return state
  }
}