import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { Space, Button, Tabs, Spin, Alert } from 'antd'
import qs from 'qs'
import { connect } from 'react-redux'
import { LoadingOutlined } from '@ant-design/icons'

import { history } from '../redux/configureStore'
import TabLayout from './TabLayout'
import {
  initCard,
  createCard,
  clearRedirect,
  getCard,
  updateCard,
  setValidation,
} from '../redux/actions/CardActions'
import getFieldsFromCol from '../utils/getFieldsFromCol'
import getFieldsConfigFromCol from '../utils/getFieldsConfigFromCol'

const { TabPane } = Tabs

class CardLayout extends PureComponent {

  constructor() {
    super()
    this.validateCard = this.validateCard.bind(this)
  }

  componentDidMount() {
    const id = this.props.match.params.id
    const { config } = this.props
    if (id === 'create') {
      let fields = {}
      if (config.tabs) {
        config.tabs.forEach(tab => {
          fields = {
            ...fields,
            ...getFieldsFromCol(tab, 'left'),
            ...getFieldsFromCol(tab, 'right'),
            ...getFieldsFromCol(tab, 'both'),
          }
        });
      }
      this.props.initCard(fields, config.url)
    } else {
      this.props.getCard(config.getApiUrl(id), config.url)
    }
  }

  componentDidUpdate() {
    const { card } = this.props
    if (card.redirect) {
      history.push(card.redirect)
      this.props.clearRedirect()
    }
  }

  changeTab(key) {
    const { config } = this.props
    const id = this.props.match.params.id

    history.push({
      pathname: config.url.replace(':id', id),
      search: qs.stringify({ tab: key }),
    })
  }

  validateCard() {
    const { config, card } = this.props
    let items = []
    if (config.tabs) {
      config.tabs.forEach(tab => {
        items = [
          ...items,
          ...getFieldsConfigFromCol(tab, 'left'),
          ...getFieldsConfigFromCol(tab, 'right'),
          ...getFieldsConfigFromCol(tab, 'both'),
        ]
      })
    }

    let errorFields = []
    items.forEach(item => {
      if (
        item.required && !card.fields[item.key] ||
        item.checkValue && !item.checkValue(card.fields)
      ) {
        errorFields.push(item.key)
      }
    })

    this.props.setValidation(errorFields)

    return errorFields
  }

  render() {
    const { config, card } = this.props
    const id = this.props.match.params.id

    let defaultActiveKey = ''
    const getVars = qs.parse(document.location.search.substr(1));
    if (getVars.tab) {
      defaultActiveKey = getVars.tab
    }

    const tabs = config.tabs.map(item => (
      <TabPane tab={item.title} key={item.key}>
        <TabLayout tab={item} />
      </TabPane>
    ))

    const antIcon = <LoadingOutlined style={{ fontSize: 40 }} />

    return (
      <>
        <Helmet>
          <title>{config.getTitle(id)}</title>
        </Helmet>

        <h1>{config.getTitle(id)}</h1>

        <Spin spinning={card.isLoading} indicator={antIcon}>
          <Space>
            {id !== 'create' && (
              <>
                <h3 style={{ marginBottom: 0 }}>ID {id}</h3>
                <Button
                  type="default"
                  onClick={() => this.props.createCard(config.getApiUrl('create'), card.fields)}
                >
                  Копировать
                </Button>
              </>
            )}
            <Button
              type="primary"
              onClick={() => {
                if (this.validateCard().length === 0) {
                  if (id === 'create') {
                    this.props.createCard(config.getApiUrl(id), card.fields)
                  } else {
                    this.props.updateCard(config.getApiUrl(id), card.fields)
                  }
                }
              }}
            >
              Сохранить
            </Button>
            <Button
              type="dashed"
              onClick={history.goBack}
            >
              Назад
            </Button>
          </Space>

          {card.errorFields.length > 0 && (
            <Alert message="Некорректно заполнены поля" type="error" style={{ marginTop: '1rem' }} />
          )}

          <Tabs
            defaultActiveKey={defaultActiveKey}
            onChange={this.changeTab.bind(this)}
            className={"Card__tabs"}
          >
            {tabs}
          </Tabs>
        </Spin>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    card: store.card,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    initCard: (fields, url) => dispatch(initCard(fields, url)),
    createCard: (url, data) => dispatch(createCard(url, data)),
    clearRedirect: () => dispatch(clearRedirect()),
    getCard: (url, cardUrl) => dispatch(getCard(url, cardUrl)),
    updateCard: (url, data) => dispatch(updateCard(url, data)),
    setValidation: (errorFields) => dispatch(setValidation(errorFields)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardLayout)