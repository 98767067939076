const config = {
  url: '/persons',
  title: 'Персоны',
  endpoint: '/admin-search?filter=persons',
  type: 'table',
  getUrl: id => `/person/${id}`,
  getApiUrl: id => `/persons/${id}`,
  search: true,
  key: 'persons',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'surname',
      width: 150,
      title: 'Фамилия',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.surname,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'name',
      width: 150,
      title: 'Имя',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.name,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'patronymic',
      width: 150,
      title: 'Отчество',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.patronymic,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: false,
      },
    },
    {
      key: 'science_organization_id',
      width: 150,
      title: 'Научная организация',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.science_organizations_title,
      edit: {
        component: 'DynamicSelect',
        endpoint: '/admin-search?filter=science_organizations&sort[field]=title&sort[type]=asc&limit=100',
        disableFilterByValue: true,
        filter: 'science_organizations',
        key: 'science_organization_id',
        keyName: 'science_organizations_name',
        placeholder: 'Выберите',
        search: true,
        mode: 'single',
        getOptionValue: data => data.title,
        required: true,
      }
    },
    {
      key: 'post',
      width: 150,
      title: 'Должность',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.post,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: false,
      },
    },
  ],
  filters: [],
}

export default config
