const config = {
  url: '/science-organizations',
  title: 'Научные организации',
  endpoint: '/admin-search?filter=science_organizations',
  type: 'table',
  getUrl: id => `/science-organization/${id}`,
  getApiUrl: id => `/science-organizations/${id}`,
  search: true,
  key: 'science-organizations',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: true,
      },
    },
    {
      key: 'short_title',
      width: 150,
      title: 'Короткое название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.short_title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: true,
      },
    },
    {
      key: 'established_year',
      width: 150,
      title: 'Год основания',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.established_year,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 4,
        required: true,
      },
    },
    {
      key: 'link',
      width: 150,
      title: 'Ссылка',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.link,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'address',
      title: 'Адрес',
      isViewByDefault: true,
      getValue: data => data.address,
      edit: {
        component: 'TextArea',
        placeholder: 'Введите текст',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'color',
      width: 150,
      title: 'Цвет (RGB)',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.color,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 6,
        required: true,
      },
    },
  ],
  filters: [],
}

export default config
