import moment from "moment"

const config = {
  url: '/events',
  title: 'События',
  endpoint: '/admin-search?filter=events',
  type: 'table',
  getUrl: id => `/event/${id}`,
  getApiUrl: id => `/events/${id}`,
  search: true,
  key: 'events',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: true,
      },
    },
    {
      key: 'date_s',
      title: 'Дата начала',
      isViewByDefault: true,
      getValue: data => moment(data.date_s, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      width: 180,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: false,
        format: 'YYYY-MM-DD',
        required: true,
      },
    },
    {
      key: 'date_e',
      title: 'Дата окончания',
      isViewByDefault: true,
      getValue: data => moment(data.date_e, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      width: 180,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: false,
        format: 'YYYY-MM-DD',
        required: true,
      },
    },
    {
      key: 'subtitle',
      width: 150,
      title: 'Подзаголовок',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.subtitle,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: false,
      },
    },
    {
      key: 'event_type_id',
      width: 150,
      title: 'Тип события',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.event_types_name,
      edit: {
        component: 'DynamicSelect',
        endpoint: '/admin-search?filter=event_types&sort[field]=name&sort[type]=asc&limit=100',
        disableFilterByValue: true,
        filter: 'event_types',
        key: 'event_type_id',
        keyName: 'event_types_name',
        placeholder: 'Выберите тип',
        search: true,
        mode: 'single',
        getOptionValue: data => data.name,
        required: true,
      }
    },
  ],
  filters: [],
}

export default config
