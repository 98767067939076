import moment from "moment"

const config = {
  url: '/actions',
  title: 'Мероприятия',
  endpoint: '/admin-search?filter=actions',
  type: 'table',
  getUrl: id => `/action/${id}`,
  getApiUrl: id => `/actions/${id}`,
  search: true,
  key: 'actions',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: true,
      },
    },
    {
      key: 'short_title',
      width: 150,
      title: 'Короткое название',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.short_title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: true,
      },
    },
    {
      key: 'date_s',
      title: 'Дата начала',
      isViewByDefault: true,
      getValue: data => moment(data.date_s, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm'),
      width: 180,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: true,
        format: 'YYYY-MM-DD HH:mm:ss',
        required: true,
      },
    },
    {
      key: 'date_e',
      title: 'Дата окончания',
      isViewByDefault: true,
      getValue: data => moment(data.date_e, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm'),
      width: 180,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: true,
        format: 'YYYY-MM-DD HH:mm:ss',
        required: true,
      },
    },
    {
      key: 'action_type_id',
      width: 150,
      title: 'Тип мероприятия',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.action_types_name,
      edit: {
        component: 'DynamicSelect',
        endpoint: '/admin-search?filter=action_types&sort[field]=name&sort[type]=asc&limit=100',
        disableFilterByValue: true,
        filter: 'action_types',
        key: 'action_type_id',
        keyName: 'action_types_name',
        placeholder: 'Выберите тип',
        search: true,
        mode: 'single',
        getOptionValue: data => data.name,
        required: true,
      }
    },
  ],
  filters: [],
}

export default config
