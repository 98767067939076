const config = {
  url: '/researche/:id',
  getTitle: id => id === 'create' ? 'Создание исследования' : 'Редактирование исследования',
  getApiUrl: id => id === 'create' ? '/researches' : `/researches/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'short_title',
            title: 'Короткое название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: true,
          },
          {
            key: 'science_organization_id',
            title: 'Научная организация',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=science_organizations&sort[field]=title&sort[type]=asc&limit=100',
            disableFilterByValue: true,
            filter: 'science_organizations',
            placeholder: 'Выберите организацию',
            search: true,
            mode: 'single',
            getOptionValue: data => data.title,
            required: false,
            relatedCard: '/science-organization/:id',
          },
          {
            key: 'persons_for_card',
            title: 'Персоны для карточки',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
        ],
        right: [
          {
            key: 'image_storage_id',
            title: 'Изображение (jpg)',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
          },
          {
            key: 'person_ids',
            title: 'Персоны',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=persons&sort[field]=surname&sort[type]=asc&limit=50',
            filter: 'persons',
            placeholder: 'Выберите персоны',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.surname + ' ' + data.name,
            required: false,
            relatedCard: '/person/:id',
          },
          {
            key: 'tag_ids',
            title: 'Теги',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=tags&sort[field]=name&sort[type]=asc&limit=200',
            disableFilterByValue: true,
            filter: 'tags',
            placeholder: 'Выберите теги',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/tag/:id',
          },
          {
            key: 'hidden',
            title: 'Скрыть',
            component: 'Switch',
            defaultValue: 0,
          },
        ],
        both: [
          {
            key: 'main_description',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [
          {
            key: 'title_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'short_title_en',
            title: 'Короткое название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'persons_for_card_en',
            title: 'Персоны для карточки (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
        ],
        right: [],
        both: [
          {
            key: 'main_description_en',
            title: 'Описание (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      },
    }
  ]
}

export default config
