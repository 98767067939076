import moment from "moment"

const config = {
  url: '/podcasts',
  title: 'Подкасты',
  endpoint: '/admin-search?filter=podcasts',
  type: 'table',
  getUrl: id => `/podcast/${id}`,
  getApiUrl: id => `/podcasts/${id}`,
  search: true,
  key: 'podcasts',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 300,
        required: true,
      },
    },
    {
      key: 'subtitle',
      width: 150,
      title: 'Подзаголовок',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.subtitle,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 300,
        required: false,
      },
    },
    {
      key: 'hearing_time',
      width: 150,
      title: 'Время прослушивания',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.hearing_time,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 10,
        required: true,
      },
    },
    {
      key: 'publication_date',
      title: 'Дата публикации',
      isViewByDefault: true,
      getValue: data => moment(data.publication_date, 'YYYY-MM-DD HH:mm:ss').format('DD.MM.YYYY HH:mm'),
      width: 180,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: true,
        format: 'YYYY-MM-DD HH:mm:ss',
        required: true,
      },
    },
    {
      key: 'heading_id',
      width: 150,
      title: 'Рубркиа',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.headings_name,
      edit: {
        component: 'DynamicSelect',
        endpoint: '/admin-search?filter=headings&sort[field]=name&sort[type]=asc&limit=100',
        disableFilterByValue: true,
        filter: 'headings',
        key: 'heading_id',
        keyName: 'headings_name',
        placeholder: 'Выберите',
        search: true,
        mode: 'single',
        getOptionValue: data => data.name,
        required: true,
      }
    },
  ],
  filters: [],
}

export default config
