const config = {
  url: '/gallery/:id',
  getTitle: id => id === 'create' ? 'Создание галлереи' : 'Редактирование галлереи',
  getApiUrl: id => id === 'create' ? '/galleries' : `/galleries/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'name',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
        ],
        right: [
          {
            key: 'image_ids',
            title: 'Изображения (jpg)',
            component: 'Image',
            multiple: true,
            maxCount: 10,
            keyName: 'image_ids',
            keyList: 'images',
            allowTypes: ['jpg'],
          },
        ],
        both: [],
      }
    }
  ]
}

export default config
