const config = {
  url: '/location/:id',
  getTitle: id => id === 'create' ? 'Создание площадки' : 'Редактирование площадки',
  getApiUrl: id => id === 'create' ? '/locations' : `/locations/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'name',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'address',
            title: 'Адрес',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 255,
            required: false,
          },
        ],
        right: [
          {
            key: 'name_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'address_en',
            title: 'Адрес (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 255,
            required: false,
          },
        ],
        both: [],
      }
    }
  ]
}

export default config
