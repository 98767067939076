import React, { PureComponent } from 'react'
import { Upload, Modal, message, Input, Space, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import ReactCrop from 'react-image-crop'
import axios from 'axios'

import 'react-image-crop/dist/ReactCrop.css'

import { setField } from '../redux/actions/CardActions'

const TYPES_TO_MIMES = {
  jpg: ['image/jpeg']
}

class ImageField extends PureComponent {

  state = {
    isOpenModal: false,
    isLoagingModal: false,
    isCrop: false,
    file: null,
    isCropChanged: false,
    crop: {
      unit: 'px',
      width: 0,
      height: 0,
      x: 0,
      y: 0
    }
  }

  constructor() {
    super();
    this.canvasImg = React.createRef()
  }

  handlePreview(file) {
    this.setState({
      isOpenModal: true,
      isCrop: false,
      isCropChanged: false,
      file
    })
  }

  writeThumbnail() {
    const canvasImg = this.canvasImg.current.getContext('2d')
    const crop = this.state.crop
    let img = new Image()
    img.onload = function () {
      let width = this.width
      let height = this.height
      canvasImg.drawImage(
        img,
        parseInt(width * crop.x / 100),
        parseInt(height * crop.y / 100),
        parseInt(width * crop.width / 100),
        parseInt(height * crop.height / 100),
        0,
        0,
        300,
        150
      );
    }
    img.src = this.state.file.thumbnails ? this.state.file.thumbnails.R700.url : this.state.file.url
  }

  saveImage() {
    this.setState({ isLoagingModal: true })

    const { card, keyList } = this.props
    const isSingleFile = this.props.maxCount === 1
    let files = []
    if (card.fields[keyList]) {
      files = isSingleFile ?
        [Object.assign({}, card.fields[keyList])] :
        card.fields[keyList].map(item => Object.assign({}, item))
    }

    const url = process.env.REACT_APP_API_HOST
    axios.put(`${url}/storage/update-image/${this.state.file.id}`, {
      file: this.state.file,
      crop: this.state.isCropChanged ? this.state.crop : null,
      thumbnailType: 'F500',
    }, {
      headers: {
        Accept: 'application/json',
        authorization: 'Bearer ' + this.props.user.attributes.token,
      }
    }).then(res => {
      this.setState({ isLoagingModal: false })
      this.setState({ isOpenModal: false })
      files.forEach((item, index) => {
        if (item.id === this.state.file.id) {
          files[index] = res.data.data
        }
      })
      this.props.setField(keyList, isSingleFile ? files[0] : files)
    })
  }

  render() {
    const { card, keyList, keyName } = this.props

    const isSingleFile = this.props.maxCount === 1
    let files = []
    if (card.fields[keyList]) {
      files = isSingleFile ? [card.fields[keyList]] : card.fields[keyList]
    }
    let fileList = files.map(item => ({
      status: 'done',
      ...item,
      uid: item.uid ? item.uid : item.id,
      thumbUrl: item.thumbnails ? item.thumbnails.R700.url : item.url,
    }))

    const url = process.env.REACT_APP_API_HOST
    const self = this
    const props = {
      name: 'file',
      action: `${url}/storage/store-image`,
      headers: {
        authorization: 'Bearer ' + this.props.user.attributes.token,
      },
      maxCount: this.props.maxCount,
      multiple: this.props.multiple,
      listType: 'picture-card',
      fileList,
      onPreview: this.handlePreview.bind(this),
      beforeUpload: file => {
        let isAllow = false
        if (self.props.allowTypes) {
          self.props.allowTypes.forEach(type => {
            if (TYPES_TO_MIMES[type].indexOf(file.type) !== -1) {
              isAllow = true
            }
          });
        } else {
          isAllow = true
        }
        if (!isAllow) {
          message.error(`${file.name} неверный формат файла`);
        }
        return isAllow || Upload.LIST_IGNORE;
      },
      onChange(info) {
        if (isSingleFile) {
          // single file
          if (info.file.status === 'done') {
            self.props.setField(keyList, info.file.response.data)
            self.props.setField(keyName, info.file.response.data.id)
          } else {
            self.props.setField(keyList, info.fileList[0])
            if (info.file.error) {
              if (info.file.error.status === 422) {
                message.error(`${info.file.name} неверный формат файла`)
              } else {
                message.error(
                  `${info.file.name} загрузка прервана. Код ошибки ${info.file.error.status}`
                )
              }
            }
          }

          if (info.fileList.length === 0) {
            self.props.setField(keyList, null)
            self.props.setField(keyName, null)
          }
        } else {
          // multiple files
          let localFileList = fileList.slice(0)
          const fileIndex = localFileList.map(item => item.uid).indexOf(info.file.uid)

          if (info.file.status === 'done') {
            localFileList[fileIndex] = info.file.response.data
            self.props.setField(keyList, localFileList)
            self.props.setField(keyName, localFileList.map(item => item.id))
          } else if (info.file.status === 'removed') {
            self.props.setField(keyList, info.fileList)
            self.props.setField(keyName, info.fileList.map(item => item.id))
          } else {
            self.props.setField(keyList, info.fileList.map(item => ({
              ...item,
              status: item.status ? item.status : ''
            })))
            if (info.file.error) {
              if (info.file.error.status === 422) {
                message.error(`${info.file.name} неверный формат файла`)
              } else {
                message.error(
                  `${info.file.name} загрузка прервана. Код ошибки ${info.file.error.status}`
                )
              }
            }
          }
        }
      },
    }

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );

    return (
      <>
        <Upload {...props}>
          {fileList.length < this.props.maxCount ? uploadButton : null}
        </Upload >
        <Modal
          visible={this.state.isOpenModal}
          title={'Редактирование изображения'}
          okText="Сохранить"
          onCancel={() => this.setState({ isOpenModal: false })}
          onOk={this.saveImage.bind(this)}
          confirmLoading={this.state.isLoagingModal}
        >
          {this.state.file && (
            <Space direction="vertical" style={{ width: '100%' }}>
              <div>
                <div style={{ display: this.state.isCrop ? 'none' : 'block' }}>
                  <div className="ImageField__modal-wrapper">
                    <img className="ImageField__modal-image" src={this.state.file.url} alt="" />

                    <canvas
                      className="ImageField__modal-thumbnail"
                      ref={this.canvasImg}
                      style={{ display: this.state.isCropChanged ? 'block' : 'none' }}
                    />

                    <img
                      className="ImageField__modal-thumbnail"
                      src={this.state.file.thumbnails.F500.url + '?' + this.state.file.thumbnails.F500.modified}
                      style={{ display: !this.state.isCropChanged ? 'block' : 'none' }}
                      alt=""
                    />

                  </div>

                  <Button type="dashed" style={{ marginTop: '5px' }} onClick={() => this.setState({ isCrop: true })}>
                    Изменить миниатюру
                  </Button>
                </div>

                <div style={{ display: !this.state.isCrop ? 'none' : 'block' }}>
                  <ReactCrop
                    crop={this.state.crop}
                    aspect={1}
                    onChange={crop => {
                      this.setState({ crop })
                    }}
                    onComplete={(crop, percentCrop) => {
                      this.setState(
                        { crop: percentCrop },
                        this.writeThumbnail.bind(this)
                      )
                    }}
                  >
                    <img className="ImageField__modal-image" src={this.state.file.url} alt="" />
                  </ReactCrop>
                  <Space>
                    <Button type="dashed" onClick={() => this.setState({ isCrop: false, isCropChanged: false })}>
                      Отмена
                    </Button>
                    <Button type="primary" onClick={() => this.setState({ isCrop: false, isCropChanged: true })}>
                      Применить
                    </Button>
                  </Space>
                </div>
              </div>

              <Input
                placeholder="Подпись"
                value={this.state.file.description}
                onChange={(e) => this.setState({
                  file: {
                    ...this.state.file,
                    description: e.target.value
                  }
                })}
              />
              <Input
                placeholder="Копирайт"
                value={this.state.file.copyright}
                onChange={(e) => this.setState({
                  file: {
                    ...this.state.file,
                    copyright: e.target.value
                  }
                })}
              />
            </Space>
          )}
        </Modal>
      </>
    );
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    card: store.card,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setField: (field, value) => dispatch(setField(field, value)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ImageField)