const config = {
  url: '/heading/:id',
  getTitle: id => id === 'create' ? 'Создание рубрики' : 'Редактирование рубрики',
  getApiUrl: id => id === 'create' ? '/headings' : `/headings/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'name',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'description',
            title: 'Описание',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
        ],
        right: [
          {
            key: 'icon_storage_id',
            title: 'Иконка (png, svg)',
            component: 'File',
            multiple: false,
            maxCount: 1,
            keyName: 'logo_storage_id',
            keyList: 'logo_storage',
            allowTypes: ['svg', 'png'],
          },
        ],
        both: [],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [
          {
            key: 'name_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'description_en',
            title: 'Описание (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
        ],
        right: [],
        both: [],
      }
    },
  ]
}

export default config
