import moment from "moment"

const config = {
  url: '/action/:id',
  getTitle: id => id === 'create' ? 'Создание мероприятия' : 'Редактирование мероприятия',
  getApiUrl: id => id === 'create' ? '/actions' : `/actions/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 200,
            required: true,
          },
          {
            key: 'short_title',
            title: 'Короткое название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 200,
            required: true,
          },
          {
            key: 'action_type_id',
            title: 'Тип мероприятия',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=action_types&sort[field]=name&sort[type]=asc&limit=100',
            disableFilterByValue: true,
            filter: 'action_types',
            keyName: 'action_types_name',
            placeholder: 'Выберите тип',
            search: true,
            mode: 'single',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/action-type/:id',
          },
          {
            key: 'location_id',
            title: 'Площадка',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=locations&sort[field]=name&sort[type]=asc&limit=100',
            disableFilterByValue: true,
            filter: 'locations',
            keyName: 'locations_name',
            placeholder: 'Выберите',
            search: true,
            mode: 'single',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/location/:id',
          },
          {
            key: 'festival_id',
            title: 'Фестиваль',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=festivals&sort[field]=name&sort[type]=asc&limit=100',
            disableFilterByValue: true,
            filter: 'festivals',
            keyName: 'festivals_name',
            placeholder: 'Выберите',
            search: true,
            mode: 'single',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/festival/:id',
          },
          {
            key: 'event_id',
            title: 'Событие',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=events&sort[field]=title&sort[type]=asc&limit=50',
            filter: 'events',
            placeholder: 'Выберите',
            search: true,
            mode: 'single',
            getOptionValue: data => data.title,
            required: false,
            relatedCard: '/event/:id',
          },
          {
            key: 'date_s',
            title: 'Дата начала',
            component: 'Date',
            placeholder: 'Введите',
            showTime: true,
            format: 'YYYY-MM-DD HH:mm:ss',
            style: { width: '100%' },
            defaultValue: moment().format('YYYY-MM-DD HH:mm:ss'),
            getValue: data => moment(data.date_s, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            required: true,
          },
          {
            key: 'date_e',
            title: 'Дата окончания',
            component: 'Date',
            placeholder: 'Введите',
            showTime: true,
            format: 'YYYY-MM-DD HH:mm:ss',
            style: { width: '100%' },
            defaultValue: moment().format('YYYY-MM-DD HH:mm:ss'),
            getValue: data => moment(data.date_e, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            required: true,
          },
          {
            key: 'science_organization_ids',
            title: 'Научные организации',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=science_organizations&sort[field]=title&sort[type]=asc&limit=100',
            filter: 'science_organizations',
            disableFilterByValue: true,
            placeholder: 'Выберите организации',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.title,
            required: false,
            relatedCard: '/science-organization/:id',
          },
          {
            key: 'color',
            title: 'Цвет (RGB)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 6,
            required: false,
          },
          {
            key: 'persons_for_card',
            title: 'Персоны для карточки',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'person_ids',
            title: 'Персоны',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=persons&sort[field]=surname&sort[type]=asc&limit=50',
            filter: 'persons',
            placeholder: 'Выберите персоны',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.surname + ' ' + data.name,
            required: false,
            relatedCard: '/person/:id',
          },
          {
            key: 'tag_ids',
            title: 'Теги',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=tags&sort[field]=name&sort[type]=asc&limit=200',
            disableFilterByValue: true,
            filter: 'tags',
            placeholder: 'Выберите теги',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/tag/:id',
          },
          {
            key: 'is_show_anons',
            title: 'Показывать анонс',
            component: 'Switch',
            defaultValue: 0,
          },
          {
            key: 'hidden',
            title: 'Скрыть',
            component: 'Switch',
            defaultValue: 0,
          },
        ],
        right: [
          {
            key: 'image_storage_id',
            title: 'Главное изображение (jpg)',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
          },
          {
            key: 'image_ids',
            title: 'Изображения (jpg)',
            component: 'Image',
            multiple: true,
            maxCount: 10,
            keyName: 'image_ids',
            keyList: 'images',
            allowTypes: ['jpg'],
          },
        ],
        both: [
          {
            key: 'short_description',
            title: 'Короткое описание',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 700,
            required: false,
          },
          {
            key: 'main_description',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [
          {
            key: 'title_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'short_title_en',
            title: 'Короткое название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'persons_for_card_en',
            title: 'Персоны для карточки (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
        ],
        right: [],
        both: [
          {
            key: 'short_description_en',
            title: 'Короткое описание (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'main_description_en',
            title: 'Описание (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
  ]
}

export default config
