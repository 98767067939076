import moment from "moment"

const config = {
  url: '/festivals',
  title: 'Фестивали',
  endpoint: '/admin-search?filter=festivals',
  type: 'table',
  getUrl: id => `/festival/${id}`,
  getApiUrl: id => `/festivals/${id}`,
  search: true,
  key: 'festivals',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'name',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.name,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: true,
      },
    },
    {
      key: 'date_s',
      title: 'Дата начала',
      isViewByDefault: true,
      getValue: data => moment(data.date_s, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      width: 180,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: true,
        format: 'YYYY-MM-DD',
        required: true,
      },
    },
    {
      key: 'date_e',
      title: 'Дата окончания',
      isViewByDefault: true,
      getValue: data => moment(data.date_e, 'YYYY-MM-DD').format('DD.MM.YYYY'),
      width: 180,
      edit: {
        component: 'Date',
        placeholder: 'Введите',
        showTime: true,
        format: 'YYYY-MM-DD',
        required: true,
      },
    },
    {
      key: 'topic',
      width: 150,
      title: 'Тема',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.topic,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: false,
      },
    },
  ],
  filters: [],
}

export default config
