import React, { PureComponent } from 'react'
import Helmet from 'react-helmet'
import { EditOutlined, DeleteOutlined, LoadingOutlined } from '@ant-design/icons'
import { Popconfirm, Divider, Spin, BackTop, Space, Button, Tooltip } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import qs from 'qs'

import { history } from '../redux/configureStore'
import AnimatedTable from '../components/AnimatedTableComponent'
import Search from '../components/SearchComponent'
import Filters from '../components/FiltersComponent'
import TableSettings from '../components/TableSettingsComponent'
import TableUnloading from '../components/TableUnloadingComponent'

import { getTable, setTableParam, updateCell, deleteRecord } from '../redux/actions/TableActions'
import getField from '../utils/getField'

class TableLayout extends PureComponent {

  state = {
    editMode: false,
  }

  componentDidMount() {
    const getVars = qs.parse(document.location.search.substr(1));
    const params = {
      ...this.props.table.reqParams,
      key: '',
      params: {},
      ...getVars,
    }
    this.props.getTable(
      this.props.config.url,
      this.props.config.endpoint,
      1,
      params
    );

    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = e => {
    if (
      this.props.table.data.isLoading ||
      this.props.table.data.isTotalReached
    ) {
      return
    }

    const body = document.body,
      html = document.documentElement

    const wholePageHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    )

    const scrollTop = window.scrollY
    const windowHeight = window.innerHeight

    if (scrollTop > wholePageHeight - windowHeight * 1.05) {
      const page = this.props.table.data.page + 1
      this.props.getTable(
        this.props.config.url,
        this.props.config.endpoint,
        page,
        this.props.table.reqParams
      );
    }
  }

  handleTableChange = (pagination, filters, sorter) => {
    let sort = null
    if (Object.keys(sorter).length > 0) {
      sort = {
        field: sorter.field,
        type: sorter.order === 'ascend' ? 'asc' : 'desc'
      }
    }
    this.props.getTable(
      this.props.config.url,
      this.props.config.endpoint,
      1,
      {
        ...this.props.table.reqParams,
        sort
      }
    );
  }

  render() {
    const { config, table, user } = this.props

    let viewColumns = []

    if (user.settings.tables[config.key] && user.settings.tables[config.key].length > 0) {
      viewColumns = user.settings.tables[config.key];
    } else {
      viewColumns = config.table.filter(item => item.isViewByDefault).map(item => item.key)
    }

    let columns = config.table
      .filter(item => viewColumns.indexOf(item.key) !== -1)
      .map(item => {
        let column = {
          title: item.title,
          dataIndex: item.key,
          sorter: item.sorter ? true : false,
        }

        if (item.width) {
          column.width = item.width
        }

        if (!this.state.editMode) {
          column.className = 'Table__read-cell'
          column.onCell = (record, rowIndex) => ({
            onClick: event => {
              history.push({
                pathname: config.getUrl(record.id),
              })
            },
          })
        }

        column.render = (text, record) => {
          return this.state.editMode && item.edit ?
            getField({
              ...item.edit,
              key: item.key,
              value: text,
              status: item.edit.required && !text ? 'error' : undefined,
              onChange: (key, value, other = {}) =>
                this.props.setTableParam(record.id, key, value, other),
              onSave: (key, value) => {
                if (item.edit.required && !value) {
                  return
                }

                this.props.updateCell(
                  config.getApiUrl(record.id),
                  record.id,
                  { [key]: value }
                )
              },
            }) : (
              <span>{text}</span>
            )
        };

        return column;
      })

    columns.push({
      title: '',
      width: 110,
      render: (text, record) => (
        <span className="AnimatedTable__control-buttons">
          <Link to={config.getUrl(record.id)}>
            <EditOutlined />
          </Link>
          <Divider type="vertical" />
          <Popconfirm
            title="Вы уверены, что хотите удалить запись?"
            onConfirm={e => {
              this.props.deleteRecord(
                config.getApiUrl(record.id),
                record.id
              )
            }}
            okText="Да"
            cancelText="Нет"
          >
            <a href="/">
              <DeleteOutlined />
            </a>
          </Popconfirm>
        </span>
      )
    })

    const data = table.data.items.map(item => {
      let row = {
        key: `${config.type}-${item.id}`
      }
      config.table.forEach(column => {
        row[column.key] = !this.state.editMode ? column.getValue(item) : item[column.key];
      });
      return row;
    })

    const antIcon = <LoadingOutlined style={{ fontSize: 40 }} />

    let tableStyle = null
    if (table.data.isLoading && table.data.page === 1) {
      tableStyle = { display: 'none' }
    }

    return (
      <>
        <Helmet>
          <title>{config.title}</title>
        </Helmet>

        <BackTop />

        <h1>
          <Space>
            {config.title}
            <TableSettings config={config} />
            <TableUnloading config={config} />
            {!this.state.editMode ? (
              <a href="/" onClick={(e) => {
                e.preventDefault()
                this.setState({ editMode: true })
              }}>
                <Tooltip placement="bottom" mouseEnterDelay={0.75} title="Редактировать таблицу">
                  <EditOutlined />
                </Tooltip>
              </a>
            ) : (
              <>
                <Button
                  type="dashed"
                  onClick={() => this.setState({ editMode: false })}
                >
                  Назад
                </Button>
              </>
            )}
            <Link to={config.getUrl('create')}>
              <Button type="primary">
                Создать
              </Button>
            </Link>
          </Space>
        </h1>

        {
          config.search && (
            <Search config={config} />
          )
        }

        {
          config.filters && (
            <Filters config={config} />
          )
        }

        <AnimatedTable
          columns={columns}
          dataSource={data}
          pagination={false}
          style={tableStyle}
          onChange={this.handleTableChange}
          rowClassName={(record) => {
            return table.data.savingItems.indexOf(record.id) !== -1 ? 'Table__disabled-row' : ''
          }}
          showSorterTooltip={false}
        />

        {
          table.data.isLoading && (
            <div className="Table__loader">
              <Spin indicator={antIcon} />
            </div>
          )
        }
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    table: store.table,
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getTable: (url, endpoint, page = 1, params = null) =>
      dispatch(getTable(url, endpoint, page, params)),
    setTableParam: (id, table, param, other = {}) =>
      dispatch(setTableParam(id, table, param, other)),
    updateCell: (url, id, data) =>
      dispatch(updateCell(url, id, data)),
    deleteRecord: (url, id) =>
      dispatch(deleteRecord(url, id)),
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(TableLayout)