const config = {
  url: '/combained-page/:id',
  getTitle: id => id === 'create' ? 'Создание комбинированной страницы' : 'Редактирование комбинированной страницы',
  getApiUrl: id => id === 'create' ? '/combained-pages' : `/combained-pages/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'url',
            title: 'URL',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
        ],
        right: [],
        both: [
          {
            key: 'zone_one',
            title: 'Блок текста №1',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
          {
            key: 'zone_two',
            title: 'Блок текста №2',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [],
        right: [],
        both: [
          {
            key: 'zone_one_en',
            title: 'Блок текста №1 (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
          {
            key: 'zone_two_en',
            title: 'Блок текста №2 (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    }
  ]
}

export default config
