import React, { PureComponent } from 'react'
import { Row, Col, Form, Input, Button, Alert, Modal } from 'antd'
import { Helmet } from 'react-helmet'
import { connect } from 'react-redux'
import { UserOutlined, LockOutlined } from '@ant-design/icons'
import axios from 'axios'

import { logInUser, initLoginPage } from '../redux/actions/UserActions'

class Login extends PureComponent {

  state = {
    isOpenModal: false,
    email: '',
    isLoadingModal: false,
    alert: {
      visible: false,
      type: 'error',
      message: '',
    }
  }

  componentDidMount = () => {
    this.props.initLoginPage();
  }

  onFinish = (values) => {
    this.props.logInUser(values.login, values.password)
  }

  render() {
    const url = process.env.REACT_APP_API_HOST

    return (
      <div className="Login">
        <Helmet>
          <title>Вход</title>
        </Helmet>
        <Row>
          <Col span={8} offset={8}>
            <h3 className="Login__title">
              Авторизируйтесь, чтобы начать использовать приложение
            </h3>
            {this.props.user.error.code && (
              <Alert
                message={this.props.user.error.text}
                type="error"
                showIcon
                style={{ marginBottom: '1rem' }}
              />
            )}
            <Form
              className="login-form"
              initialValues={{ remember: true }}
              onFinish={this.onFinish}
              autoComplete="off"
            >

              <Form.Item
                name="login"
                rules={[{ required: true, message: 'Введите логин' }]}
              >
                <Input
                  prefix={
                    <UserOutlined type="user" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  placeholder="Логин"
                  size="large"
                />
              </Form.Item>

              <Form.Item
                name="password"
                rules={[{ required: true, message: 'Введите пароль' }]}
              >
                <Input
                  prefix={
                    <LockOutlined type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />
                  }
                  type="password"
                  placeholder="Пароль"
                  size="large"
                />
              </Form.Item>

              <Form.Item className="Login__button-row">
                <Button
                  type="primary"
                  htmlType="submit"
                  className="Login__login-button"
                  size="large"
                  loading={this.props.user.isLoading}
                >
                  Войти
                </Button>
              </Form.Item>

              <Form.Item className="Login__button-row">
                <Button
                  type="link"
                  className="Login__login-button"
                  onClick={() => this.setState({ isOpenModal: true })}
                >
                  Забыли пароль?
                </Button>
              </Form.Item>

            </Form>
          </Col>
        </Row>

        <Modal
          title="Восстановление пароля"
          visible={this.state.isOpenModal}
          onOk={() => {
            this.setState({ isLoadingModal: true })
            axios.post(`${url}/auth/reset-password`, {
              login: this.state.email,
            }).then(res => {
              this.setState({
                isLoadingModal: false,
                alert: {
                  visible: true,
                  type: 'success',
                  message: 'Новый пароль выслан на указанный адрес электронной почты',
                }
              })
            }).catch(e => {
              let message = ''
              switch (e.response.status) {
                case 403:
                  message = 'На указанный адрес электронной почты уже был выслан новый пароль. Проверьте папку Спам.'
                  break
                case 404:
                  message = 'Пользователь с таким адресом электронной почты не найден'
                  break
                default:
                  break
              }
              this.setState({
                isLoadingModal: false,
                alert: {
                  visible: true,
                  type: 'error',
                  message,
                }
              })
            })
          }}
          onCancel={() => this.setState({ isOpenModal: false })}
          okText="Восстановить"
          cancelText="Закрыть"
          confirmLoading={this.state.isLoadingModal}
        >
          <p>
            Введите адрес электронной почты в поле ниже.
            На него будет отправлен новый пароль.
          </p>
          {this.state.alert.visible && (
            <Alert
              message={this.state.alert.message}
              type={this.state.alert.type}
              showIcon
              style={{ marginBottom: '1rem' }}
            />
          )}
          <Input
            placeholder="E-mail"
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        </Modal>
      </div >
    )
  }

}

const mapStateToProps = store => {
  return {
    user: store.user,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logInUser: (login, password) => dispatch(logInUser(login, password)),
    initLoginPage: () => dispatch(initLoginPage()),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Login)
