const config = {
  url: '/event-type/:id',
  getTitle: id => id === 'create' ? 'Создание типа события' : 'Редактирование типа события',
  getApiUrl: id => id === 'create' ? '/event-types' : `/event-types/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'name',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
        ],
        right: [
          {
            key: 'name_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
        ],
        both: [],
      }
    }
  ]
}

export default config
