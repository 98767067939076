const config = {
  url: '/publication/:id',
  getTitle: id => id === 'create' ? 'Создание издания' : 'Редактирование издания',
  getApiUrl: id => id === 'create' ? '/publications' : `/publications/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
          {
            key: 'short_title',
            title: 'Короткое название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: true,
          },
          {
            key: 'place',
            title: 'Место издания',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'year',
            title: 'Год издания',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 4,
            required: false,
          },
          {
            key: 'authors',
            title: 'Короткое описание',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 200,
            required: false,
          },
        ],
        right: [
          {
            key: 'image_storage_id',
            title: 'Изображение (jpg)',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
          },
          {
            key: 'link',
            title: 'Ссылка',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'link_text',
            title: 'Текст ссылки',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'tag_ids',
            title: 'Теги',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=tags&sort[field]=name&sort[type]=asc&limit=200',
            disableFilterByValue: true,
            filter: 'tags',
            placeholder: 'Выберите теги',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/tag/:id',
          },
          {
            key: 'create_page',
            title: 'Создать страницу',
            component: 'Switch',
            defaultValue: 0,
          },
        ],
        both: [
          {
            key: 'annotation',
            title: 'Аннотация',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [
          {
            key: 'title_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'short_title_en',
            title: 'Короткое название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'place_en',
            title: 'Место издания (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'authors_en',
            title: 'Короткое описание (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 200,
            required: false,
          },
        ],
        right: [
          {
            key: 'link_text_en',
            title: 'Текст ссылки (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
        ],
        both: [
          {
            key: 'annotation_en',
            title: 'Аннотация (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
  ]
}

export default config
