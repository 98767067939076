const config = {
  url: '/researches',
  title: 'Исследования',
  endpoint: '/admin-search?filter=researches',
  type: 'table',
  getUrl: id => `/researche/${id}`,
  getApiUrl: id => `/researches/${id}`,
  search: true,
  key: 'researches',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'short_title',
      width: 150,
      title: 'Короткое название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.short_title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: true,
      },
    },
    {
      key: 'science_organization_id',
      width: 150,
      title: 'Научная организация',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.science_organizations_title,
      edit: {
        component: 'DynamicSelect',
        endpoint: '/admin-search?filter=science_organizations&sort[field]=title&sort[type]=asc&limit=100',
        disableFilterByValue: true,
        filter: 'science_organizations',
        key: 'science_organization_id',
        keyName: 'science_organizations_name',
        placeholder: 'Выберите',
        search: true,
        mode: 'single',
        getOptionValue: data => data.title,
        required: true,
      }
    },
  ],
  filters: [],
}

export default config
