import moment from "moment"

const config = {
  url: '/podcast/:id',
  getTitle: id => id === 'create' ? 'Создание подкаста' : 'Редактирование подкаста',
  getApiUrl: id => id === 'create' ? '/podcasts' : `/podcasts/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 300,
            required: true,
          },
          {
            key: 'subtitle',
            title: 'Подзаголовок',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 300,
            required: false,
          },
          {
            key: 'hearing_time',
            title: 'Время прослушивания (мин)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 10,
            required: true,
          },
          {
            key: 'publication_date',
            title: 'Дата публикации',
            component: 'Date',
            placeholder: 'Введите',
            showTime: true,
            format: 'YYYY-MM-DD HH:mm:ss',
            style: { width: '100%' },
            defaultValue: moment().format('YYYY-MM-DD HH:mm:ss'),
            getValue: data => moment(data.publication_date, 'YYYY-MM-DD HH:mm:ss').format('YYYY-MM-DD HH:mm:ss'),
            required: true,
          },
          {
            key: 'link',
            title: 'Ссылка',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'heading_id',
            title: 'Рубрика',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=headings&sort[field]=name&sort[type]=asc&limit=50',
            filter: 'headings',
            keyName: 'headings_name',
            placeholder: 'Выберите',
            search: true,
            mode: 'single',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/heading/:id',
          },
          {
            key: 'science_organization_id',
            title: 'Научная организация',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=science_organizations&sort[field]=title&sort[type]=asc&limit=50',
            filter: 'science_organizations',
            placeholder: 'Выберите организацию',
            search: true,
            mode: 'single',
            getOptionValue: data => data.title,
            required: false,
            relatedCard: '/science-organization/:id',
          },
          {
            key: 'podcast_series_id',
            title: 'Серия подкаста',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=podcast_series&sort[field]=name&sort[type]=asc&limit=50',
            filter: 'podcast_series',
            keyName: 'podcast_series_name',
            placeholder: 'Выберите',
            search: true,
            mode: 'single',
            getOptionValue: data => data.name,
            required: false,
          },
        ],
        right: [
          {
            key: 'image_storage_id',
            title: 'Главное изображение (jpg)',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
          },
          {
            key: 'person_ids',
            title: 'Персоны',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=persons&sort[field]=surname&sort[type]=asc&limit=50',
            filter: 'persons',
            placeholder: 'Выберите персоны',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.surname + ' ' + data.name,
            required: false,
            relatedCard: '/person/:id',
          },
          {
            key: 'action_ids',
            title: 'Мероприятия',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=actions&sort[field]=title&sort[type]=asc&limit=50',
            filter: 'actions',
            placeholder: 'Выберите',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.title,
            required: false,
            relatedCard: '/action/:id',
          },
          {
            key: 'tag_ids',
            title: 'Теги',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=tags&sort[field]=name&sort[type]=asc&limit=20',
            filter: 'tags',
            placeholder: 'Выберите теги',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/tag/:id',
          },
          {
            key: 'hidden',
            title: 'Скрыть',
            component: 'Switch',
            defaultValue: 0,
          },
        ],
        both: [
          {
            key: 'short_description',
            title: 'Короткое описание',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'main_description',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [
          {
            key: 'title_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 300,
            required: false,
          },
          {
            key: 'subtitle_en',
            title: 'Подзаголовок (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 300,
            required: false,
          },
        ],
        right: [],
        both: [
          {
            key: 'short_description_en',
            title: 'Короткое описание (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'main_description_en',
            title: 'Описание (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
  ]
}

export default config
