
import pagesTable from './pagesTable'
import pagesCard from './pagesCard'
import usersTable from './usersTable'
import usersCard from './usersCard'
import examplesTable from './examplesTable'
import examplesCard from './examplesCard'
import tagsTable from './tagsTable'
import tagsCard from './tagsCard'

import eventTypesTable from './eventTypesTable'
import eventTypesCard from './eventTypesCard'
import actionTypesTable from './actionTypesTable'
import actionTypesCard from './actionTypesCard'
import locationsTable from './locationsTable'
import locationsCard from './locationsCard'
import partnersTable from './partnersTable'
import partnersCard from './partnersCard'
import headingsTable from './headingsTable'
import headingsCard from './headingsCard'
import podcastSeriesTable from './podcastSeriesTable'
import podcastSeriesCard from './podcastSeriesCard'
import videoSeriesTable from './videoSeriesTable'
import videoSeriesCard from './videoSeriesCard'
import tutorialTypesTable from './tutorialTypesTable'
import tutorialTypesCard from './tutorialTypesCard'
import eventsTable from './eventsTable'
import eventsCard from './eventsCard'
import scienceOrganizationsTable from './scienceOrganizationsTable'
import scienceOrganizationsCard from './scienceOrganizationsCard'
import personsTable from './personsTable'
import personsCard from './personsCard'
import researchesTable from './researchesTable'
import researchesCard from './researchesCard'
import publicationsTable from './publicationsTable'
import publicationsCard from './publicationsCard'
import actionsTable from './actionsTable'
import actionsCard from './actionsCard'
import articlesTable from './articlesTable'
import articlesCard from './articlesCard'
import podcastsTable from './podcastsTable'
import podcastsCard from './podcastsCard'
import videosTable from './videosTable'
import videosCard from './videosCard'
import combainedPagesTable from './combainedPagesTable'
import combainedPagesCard from './combainedPagesCard'
import tutorialsTable from './tutorialsTable'
import tutorialsCard from './tutorialsCard'
import galleriesTable from './galleriesTable'
import galleriesCard from './galleriesCard'
import festivalsTable from './festivalsTable'
import festivalsCard from './festivalsCard'

const pages = [
  pagesTable,
  pagesCard,
  usersTable,
  usersCard,
  examplesTable,
  examplesCard,
  tagsTable,
  tagsCard,

  eventTypesTable,
  eventTypesCard,
  actionTypesTable,
  actionTypesCard,
  locationsTable,
  locationsCard,
  partnersTable,
  partnersCard,
  headingsTable,
  headingsCard,
  podcastSeriesTable,
  podcastSeriesCard,
  videoSeriesTable,
  videoSeriesCard,
  tutorialTypesTable,
  tutorialTypesCard,
  eventsTable,
  eventsCard,
  scienceOrganizationsTable,
  scienceOrganizationsCard,
  personsTable,
  personsCard,
  researchesTable,
  researchesCard,
  publicationsTable,
  publicationsCard,
  actionsTable,
  actionsCard,
  articlesTable,
  articlesCard,
  podcastsTable,
  podcastsCard,
  videosTable,
  videosCard,
  combainedPagesTable,
  combainedPagesCard,
  tutorialsTable,
  tutorialsCard,
  galleriesTable,
  galleriesCard,
  festivalsTable,
  festivalsCard,
]

const menu = [
  {
    title: 'Наука ▼',
    key: 'submenu-science',
    submenu: [
      {
        title: 'Научные организации',
        page: scienceOrganizationsTable,
      },
      {
        title: 'Учебные пособия',
        page: tutorialsTable,
      },
      {
        title: 'Типы учебных пособий',
        page: tutorialTypesTable,
      },
      {
        title: 'Персоны',
        page: personsTable,
      },
      {
        title: 'Исследования',
        page: researchesTable,
      },
      {
        title: 'Издания',
        page: publicationsTable,
      },
      {
        title: 'Теги',
        page: tagsTable,
      },
    ],
  },
  {
    title: 'События ▼',
    key: 'submenu-events',
    submenu: [
      {
        title: 'Фестивали',
        page: festivalsTable,
      },
      {
        title: 'События',
        page: eventsTable,
      },
      {
        title: 'Типы событий',
        page: eventTypesTable,
      },
      {
        title: 'Мероприятия',
        page: actionsTable,
      },
      {
        title: 'Типы мероприятий',
        page: actionTypesTable,
      },
      {
        title: 'Площадки',
        page: locationsTable,
      },
      {
        title: 'Партнеры',
        page: partnersTable,
      },
    ],
  },
  {
    title: 'Медиа ▼',
    key: 'submenu-media',
    submenu: [
      {
        title: 'Рубрики',
        page: headingsTable,
      },
      {
        title: 'Статьи',
        page: articlesTable,
      },
      {
        title: 'Подкасты',
        page: podcastsTable,
      },
      {
        title: 'Серии подкастов',
        page: podcastSeriesTable,
      },
      {
        title: 'Видео',
        page: videosTable,
      },
      {
        title: 'Серии видео',
        page: videoSeriesTable,
      },
      {
        title: 'Галереи',
        page: galleriesTable,
      },
    ],
  },
  {
    title: 'Страницы ▼',
    key: 'submenu-pages',
    submenu: [
      {
        title: 'Страницы',
        page: pagesTable,
      },
      {
        title: 'Комбинированные страницы',
        page: combainedPagesTable,
      },
    ],
  },
  {
    title: 'Пользователи',
    page: usersTable,
  },
]

const config = {
  pages,
  menu
}

export default config