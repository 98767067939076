export const INIT_CARD = 'SIMPLE_CARD/INIT_CARD'

export function initCard(fields, url) {
  return dispatch => {
    dispatch({
      type: INIT_CARD,
      payload: {
        fields,
        url,
      }
    })
  }
}

export const SET_FIELD = 'SIMPLE_CARD/SET_FIELD'

export function setField(field, value) {
  return dispatch => {
    dispatch({
      type: SET_FIELD,
      payload: {
        field,
        value,
      }
    })
  }
}

export const CREATE_REQUEST = 'SIMPLE_CARD/CREATE_REQUEST'
export const CREATE_SUCCESS = 'SIMPLE_CARD/CREATE_SUCCESS'

export function createCard(url, data) {
  return dispatch => {
    dispatch({
      types: [CREATE_REQUEST, CREATE_SUCCESS],
      payload: {
        request: {
          method: 'POST',
          url,
          data,
        },
      }
    })
  }
}

export const GET_REQUEST = 'SIMPLE_CARD/GET_REQUEST'
export const GET_SUCCESS = 'SIMPLE_CARD/GET_SUCCESS'

export function getCard(url, cardUrl) {
  return dispatch => {
    dispatch({
      types: [GET_REQUEST, GET_SUCCESS],
      payload: {
        request: {
          method: 'GET',
          url,
        },
        cardUrl
      }
    })
  }
}

export const UPDATE_REQUEST = 'SIMPLE_CARD/UPDATE_REQUEST'
export const UPDATE_SUCCESS = 'SIMPLE_CARD/UPDATE_SUCCESS'

export function updateCard(url, data) {
  return dispatch => {
    dispatch({
      types: [UPDATE_REQUEST, UPDATE_SUCCESS],
      payload: {
        request: {
          method: 'PUT',
          url,
          data,
        },
      }
    })
  }
}

export const SET_VALIDATION = 'SIMPLE_CARD/SET_VALIDATION'

export function setValidation(errorFields) {
  return dispatch => {
    dispatch({
      type: SET_VALIDATION,
      payload: {
        errorFields,
      }
    })
  }
}

export const UPDATE_MODAL = 'SIMPLE_CARD/UPDATE_MODAL'

export function updateModal(isOpen, openId = null) {
  return dispatch => {
    dispatch({
      type: UPDATE_MODAL,
      payload: {
        isOpen,
        openId,
      }
    })
  }
}

export const RESET_NEW_ID = 'SIMPLE_CARD/RESET_NEW_ID'

export function resetNewId() {
  return dispatch => {
    dispatch({
      type: RESET_NEW_ID,
    })
  }
}