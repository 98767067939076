import React, { PureComponent } from 'react'
import { Router, Switch, Route, Link, Redirect } from 'react-router-dom'
import { Layout, Menu, message } from 'antd'
import { connect } from 'react-redux'

import { history } from '../redux/configureStore'
import { logOutUser } from '../redux/actions/UserActions'
import NotFound from '../containers/NotFoundContainer'
import TableLayout from './TableLayout'
import CardLayout from './CardLayout'
import Personal from '../containers/PersonalContainer'

import config from '../config'

const { SubMenu } = Menu

class MainLayout extends PureComponent {
  state = {
    pathname: document.location.pathname,
  }

  logOut(e) {
    e.preventDefault()
    this.props.logOutUser(this.props.user.attributes.token)
  }

  componentDidMount() {
    this.checkAuth()
    const self = this
    this.unregisterHistoryListener = history.listen((location) => {
      self.setState({ pathname: location.pathname })
    })
  }

  componentWillUnmount() {
    this.unregisterHistoryListener()
  }

  componentDidUpdate() {
    if (this.props.server.error.status) {
      message.error(this.props.server.error.status + ': ' + this.props.server.error.text)
    }

    this.checkAuth()
  }

  checkAuth() {
    if (!this.props.user.authorized) {
      document.location = '/login'
    }
  }

  getMenuItem(item) {
    const { user } = this.props
    if (item.page.access.indexOf(user.type) !== -1 || item.page.access[0] === '*') {
      return (
        <Menu.Item key={item.page.url}>
          <Link to={item.page.url}>{item.title}</Link>
        </Menu.Item>
      )
    } else {
      return null
    }
  }

  render() {
    const { user } = this.props
    const pathname = this.state.pathname ? this.state.pathname : '/'

    if (!user.authorized) {
      return null
    }

    const { Header, Content, Footer } = Layout
    const date = new Date()

    const menuItems = config.menu.map(item => {
      let menuItem = null
      if (item.submenu) {
        let subMenuItems = item.submenu.map(subitem => this.getMenuItem(subitem))
        let hasSubMenuItems = subMenuItems.filter(subitem => subitem !== null)
        if (hasSubMenuItems.length > 0) {
          menuItem = (
            <SubMenu key={item.key} title={item.title}>
              {subMenuItems}
            </SubMenu>
          )
        }
      } else {
        menuItem = this.getMenuItem(item)
      }
      return menuItem
    })

    const routeItems = config.pages.map(item => {
      if (item.access.indexOf(user.type) === -1 && item.access[0] !== '*') {
        return null
      }

      if (item.type === 'table') {
        return <Route exact key={item.url} path={item.url} render={(props) => <TableLayout {...props} config={item} />} />
      } else {
        return <Route exact key={item.url} path={item.url} render={(props) => <CardLayout {...props} config={item} />} />
      }
    })

    return (
      <Layout>
        <Header className="Header">
          <div className="Header__userInfo">
            <Link to="/personal" className="Header__user-link">
              {user.attributes.name}{' '}
              {user.attributes.surname}
            </Link>
            <Link
              className="Header__exit-link"
              onClick={this.logOut.bind(this)}
              to=""
            >
              Выход
            </Link>
          </div>
          <Menu
            theme="dark"
            mode="horizontal"
            selectedKeys={[pathname]}
            style={{ lineHeight: '64px' }}
          >
            {menuItems}
          </Menu>
        </Header>
        <Content className="Content">
          <div className="Content__container">
            <Router history={history}>
              <Switch>
                <Redirect from="/login" to="/" />

                {routeItems}
                <Route exact path="/personal" component={Personal} />
                <Route component={NotFound} />
              </Switch>
            </Router>
          </div>
        </Content>
        <Footer className="Footer">
          ©{date.getFullYear()}, <a href="https://museum.systems" rel="noopener noreferrer" target="_blank">Музейные кодовые системы</a>
        </Footer>
      </Layout>
    )
  }
}

const mapStateToProps = store => {
  return {
    user: store.user,
    server: store.server,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    logOutUser: (token) => dispatch(logOutUser(token)),
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainLayout)
