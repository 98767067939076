const config = {
  url: '/tutorial/:id',
  getTitle: id => id === 'create' ? 'Создание учебного пособия' : 'Редактирование учебного пособия',
  getApiUrl: id => id === 'create' ? '/tutorials' : `/tutorials/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 500,
            required: true,
          },
          {
            key: 'short_title',
            title: 'Короткое название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: true,
          },
          {
            key: 'tutorial_type_id',
            title: 'Тип',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=tutorial_types&sort[field]=name&sort[type]=asc&limit=100',
            disableFilterByValue: true,
            filter: 'tutorial_types',
            keyName: 'tutorial_types_name',
            placeholder: 'Выберите',
            search: true,
            mode: 'single',
            getOptionValue: data => data.name,
            required: false,
          },
          {
            key: 'science_organization_id',
            title: 'Научная организация',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=science_organizations&sort[field]=title&sort[type]=asc&limit=100',
            disableFilterByValue: true,
            filter: 'science_organizations',
            placeholder: 'Выберите организацию',
            search: true,
            mode: 'single',
            getOptionValue: data => data.title,
            required: false,
            relatedCard: '/science-organization/:id',
          },
          {
            key: 'person_ids',
            title: 'Персоны',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=persons&sort[field]=surname&sort[type]=asc&limit=50',
            filter: 'persons',
            placeholder: 'Выберите персоны',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.surname + ' ' + data.name,
            required: false,
            relatedCard: '/person/:id',
          },
        ],
        right: [
          {
            key: 'image_storage_id',
            title: 'Главное изображение (jpg)',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
          },
          {
            key: 'pdf_storage_id',
            title: 'Файл (pdf)',
            component: 'File',
            multiple: false,
            maxCount: 1,
            keyName: 'pdf_storage_id',
            keyList: 'pdf_storage',
            allowTypes: ['pdf'],
          },
          {
            key: 'image_ids',
            title: 'Изображения (jpg)',
            component: 'Image',
            multiple: true,
            maxCount: 10,
            keyName: 'image_ids',
            keyList: 'images',
            allowTypes: ['jpg'],
          },
        ],
        both: [
          {
            key: 'short_description',
            title: 'Короткое описание',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'main_description',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [
          {
            key: 'title_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 500,
            required: false,
          },
          {
            key: 'short_title_en',
            title: 'Короткое название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
        ],
        right: [],
        both: [
          {
            key: 'short_description_en',
            title: 'Короткое описание (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'main_description_en',
            title: 'Описание (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
  ]
}

export default config
