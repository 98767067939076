const config = {
  url: '/publications',
  title: 'Издания',
  endpoint: '/admin-search?filter=publications',
  type: 'table',
  getUrl: id => `/publication/${id}`,
  getApiUrl: id => `/publications/${id}`,
  search: true,
  key: 'publications',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'short_title',
      width: 150,
      title: 'Короткое название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.short_title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 100,
        required: true,
      },
    },
    {
      key: 'authors',
      title: 'Авторы',
      isViewByDefault: true,
      getValue: data => data.authors,
      edit: {
        component: 'TextArea',
        placeholder: 'Введите текст',
        maxLength: 255,
        required: true,
      },
    },
  ],
  filters: [],
}

export default config
