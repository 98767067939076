import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Select, Row, Col, Button } from 'antd'

import { DeleteOutlined } from '@ant-design/icons'

import SimpleCardLayout from '../layouts/SimpleCardLayout'
import { getData } from '../redux/actions/DataActions'

const { Option } = Select

let searchTimer

class StaticSelect extends PureComponent {
  constructor() {
    super()
    this.loadSelectedItems = this.loadSelectedItems.bind(this)
  }

  componentDidMount() {
    this.loadSelectedItems(this.props.value)
  }

  loadSelectedItems(value) {
    let params = {
      id: value,
    }

    if (this.props.disableFilterByValue) {
      params.id = undefined
    }

    this.props.getData(
      this.props.filter,
      this.props.endpoint,
      '',
      params
    )
  }

  generateTemplate() {
    if (!this.props.template) {
      return null
    }

    const { filter, data } = this.props

    let dataItem = {
      isLoading: true,
      items: []
    }

    if (data[filter]) {
      dataItem = data[filter]
    }

    let value = this.props.value

    if (!Array.isArray(value)) {
      value = value ? [parseInt(value)] : []
    } else {
      value = value.map(item => parseInt(item))
    }

    let templateItems = []

    value.forEach(id => {
      let templateItem = dataItem.items.find(item => item.id === id)
      if (templateItem) {
        templateItems.push(
          <Row wrap={false} key={id} align="middle" gutter={[10, 0]} className={"DynamicSelect__template-row"}>
            <Col flex="auto">
              {this.props.template.getTemplateValue(templateItem)}
            </Col>
            <Col flex="none">
              {this.props.template.allowEdit && (
                <SimpleCardLayout
                  cardUrl={this.props.relatedCard}
                  id={id}
                  buttonType="link"
                  onEdit={() => this.loadSelectedItems(this.props.value)}
                />
              )}
              {this.props.template.allowDelete && (
                <Button
                  type="link"
                  shape="circle"
                  icon={<DeleteOutlined />}
                  onClick={() => {
                    let newValue = value.filter(currentId => currentId !== id)
                    if (!Array.isArray(this.props.value)) {
                      newValue = newValue.length > 0 ? String(newValue[0]) : null
                    } else {
                      newValue = newValue.map(item => String(item))
                    }
                    this.props.onChange(
                      this.props.name,
                      newValue,
                    )
                  }}
                />
              )}
            </Col>
          </Row>
        )
      }
    })

    return <div className={'DynamicSelect__template'}>{templateItems}</div>
  }

  render() {
    const { filter, data } = this.props

    let dataItem = {
      isLoading: true,
      items: []
    }

    if (data[filter]) {
      dataItem = data[filter]
    }

    let items = dataItem.items.map(item => (
      <Option key={filter + '-' + item.id} value={String(item.id)}>
        {this.props.getOptionValue(item)}
      </Option>
    ))

    let additionalParams = {}

    if (this.props.search) {
      additionalParams = {
        showSearch: true,
        onSearch: (key) => {
          clearTimeout(searchTimer)
          searchTimer = setTimeout(() => {
            this.props.getData(
              this.props.filter,
              this.props.endpoint,
              key
            )
          }, 500)
        },
        filterOption: value => true,
      }
    }

    let value = this.props.value

    if (!Array.isArray(value)) {
      value = value ? String(value) : undefined
    } else {
      value = value.map(item => String(item))
    }

    return (
      <>
        {this.generateTemplate()}
        <Row wrap={false} gutter={[10, 0]}>
          {!this.props.hideSelect && (
            <Col flex="auto">
              <Select
                style={{ width: '100%' }}
                placeholder={this.props.placeholder}
                allowClear={this.props.allowClear ? true : false}
                onChange={(value, option) => {
                  if (this.props.onChange) {
                    this.props.onChange(
                      this.props.name,
                      value ? value : (this.props.mode === 'single' ? null : []),
                      {
                        [this.props.keyName]: option ? option.children : undefined
                      }
                    )
                  }

                  if (this.props.onSave) {
                    this.props.onSave(
                      this.props.name,
                      value ? value : (this.props.mode === 'single' ? null : [])
                    )
                  }
                }}
                mode={this.props.mode}
                value={value}
                loading={dataItem.isLoading}
                status={this.props.status}
                {...additionalParams}
              >
                {items}
              </Select >
            </Col>
          )}
          {this.props.relatedCard && (
            <Col flex="none">
              <SimpleCardLayout
                cardUrl={this.props.relatedCard}
                id="create"
                buttonType="default"
                onCreate={(id) => {
                  let newValue = null
                  if (!Array.isArray(value)) {
                    newValue = String(id)
                  } else {
                    newValue = value
                    newValue.push(String(id))
                  }
                  this.props.onChange(
                    this.props.name,
                    newValue,
                  )
                  this.loadSelectedItems(newValue)
                }}
              />
            </Col>
          )}
        </Row>
      </>
    )
  }
}

const mapStateToProps = store => {
  return {
    data: store.data,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    getData: (filter, endpoint, key = '', params = {}) =>
      dispatch(getData(filter, endpoint, key, params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(StaticSelect)