const config = {
  url: '/combained-pages',
  title: 'Комбинированные страницы',
  endpoint: '/admin-search?filter=combained_pages',
  type: 'table',
  getUrl: id => `/combained-page/${id}`,
  getApiUrl: id => `/combained-pages/${id}`,
  search: true,
  key: 'combained-pages',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'url',
      width: 150,
      title: 'URL',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.url,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },

    },
  ],
  filters: [],
}

export default config
