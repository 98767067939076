const config = {
  url: '/video-one-series/:id',
  getTitle: id => id === 'create' ? 'Создание серии видео' : 'Редактирование серии видео',
  getApiUrl: id => id === 'create' ? '/video-series' : `/video-series/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'name',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: true,
          },
        ],
        right: [
          {
            key: 'name_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
        ],
        both: [],
      }
    }
  ]
}

export default config
