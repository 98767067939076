import { combineReducers } from 'redux'
import user from './user'
import server from './server'
import table from './table'
import data from './data'
import card from './card'
import simpleCard from './simpleCard'
import personal from './personal'

export default combineReducers({
  user,
  server,
  table,
  data,
  card,
  personal,
  simpleCard,
})
