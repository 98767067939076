const config = {
  url: '/podcast-series',
  title: 'Серии подкастов',
  endpoint: '/admin-search?filter=podcast_series',
  type: 'table',
  getUrl: id => `/podcast-one-series/${id}`,
  getApiUrl: id => `/podcast-series/${id}`,
  search: true,
  key: 'podcast-series',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'name',
      width: 150,
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.name,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },

    },
  ],
  filters: [],
}

export default config
