const config = {
  url: '/science-organization/:id',
  getTitle: id => id === 'create' ? 'Создание научной организации' : 'Редактирование научной организации',
  getApiUrl: id => id === 'create' ? '/science-organizations' : `/science-organizations/${id}`,
  type: 'card',
  access: ['*'],
  tabs: [
    {
      key: 'common',
      title: 'Общее',
      children: {
        left: [
          {
            key: 'title',
            title: 'Название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: true,
          },
          {
            key: 'short_title',
            title: 'Короткое название',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: true,
          },
          {
            key: 'established_year',
            title: 'Год основания',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 4,
            required: false,
          },
          {
            key: 'address',
            title: 'Адрес',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 255,
            required: false,
          },
          {
            key: 'link',
            title: 'Ссылка',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 255,
            required: false,
          },
          {
            key: 'color',
            title: 'Цвет (RGB)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 6,
            required: false,
          },
          {
            key: 'director_id',
            title: 'Руководитель',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=persons&sort[field]=surname&sort[type]=asc&limit=50',
            filter: 'persons',
            keyName: 'persons_surname',
            placeholder: 'Выберите персону',
            search: true,
            mode: 'single',
            getOptionValue: data => data.surname + ' ' + data.name,
            required: false,
          },
          {
            key: 'director_post',
            title: 'Должность руководителя',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'is_resident',
            title: 'Резидент НК',
            component: 'Switch',
            defaultValue: 0,
          },
          {
            key: 'tag_ids',
            title: 'Теги',
            component: 'DynamicSelect',
            endpoint: '/admin-search?filter=tags&sort[field]=name&sort[type]=asc&limit=200',
            disableFilterByValue: true,
            filter: 'tags',
            placeholder: 'Выберите теги',
            search: true,
            mode: 'multiple',
            getOptionValue: data => data.name,
            required: false,
            relatedCard: '/tag/:id',
          },
          {
            key: 'direction_of_science_research',
            title: 'Направление научных исследований',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'science_interests',
            title: 'Научные интересы',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'collections',
            title: 'Коллекции',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
        ],
        right: [
          {
            key: 'image_storage_id',
            title: 'Главное изображение (jpg)',
            component: 'Image',
            multiple: false,
            maxCount: 1,
            keyName: 'image_storage_id',
            keyList: 'image_storage',
            allowTypes: ['jpg'],
          },
          {
            key: 'logo_storage_id',
            title: 'Логотип (png, svg)',
            component: 'File',
            multiple: false,
            maxCount: 1,
            keyName: 'logo_storage_id',
            keyList: 'logo_storage',
            allowTypes: ['svg', 'png'],
          },
          {
            key: 'image_ids',
            title: 'Изображения (jpg)',
            component: 'Image',
            multiple: true,
            maxCount: 10,
            keyName: 'image_ids',
            keyList: 'images',
            allowTypes: ['jpg'],
          },
          {
            key: 'hidden',
            title: 'Скрыть',
            component: 'Switch',
            defaultValue: 0,
          },
        ],
        both: [
          {
            key: 'short_description',
            title: 'Короткое описание',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'main_description',
            title: 'Описание',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      }
    },
    {
      key: 'common_en',
      title: 'Общее (en)',
      children: {
        left: [
          {
            key: 'title_en',
            title: 'Название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'short_title_en',
            title: 'Короткое название (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'address_en',
            title: 'Адрес (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 255,
            required: false,
          },
          {
            key: 'director_post_en',
            title: 'Должность руководителя (en)',
            component: 'Input',
            placeholder: 'Введите',
            maxLength: 100,
            required: false,
          },
          {
            key: 'direction_of_science_research_en',
            title: 'Направление научных исследований (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'science_interests_en',
            title: 'Научные интересы (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'collections_en',
            title: 'Коллекции (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
        ],
        right: [],
        both: [
          {
            key: 'short_description_en',
            title: 'Короткое описание (en)',
            component: 'TextArea',
            placeholder: 'Введите текст',
            maxLength: 500,
            required: false,
          },
          {
            key: 'main_description_en',
            title: 'Описание (en)',
            component: 'CKEditor',
            placeholder: 'Введите текст',
            required: false,
            isFixedHeight: false,
            height: '200px'
          },
        ],
      },
    },
  ]
}

export default config
