const config = {
  url: '/',
  title: 'Страницы',
  endpoint: '/admin-search?filter=pages',
  type: 'table',
  getUrl: id => `/page/${id}`,
  getApiUrl: id => `/pages/${id}`,
  search: true,
  key: 'pages',
  access: ['*'],
  table: [
    {
      key: 'id',
      width: 75,
      title: 'ID',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.id
    },
    {
      key: 'title',
      title: 'Название',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.title,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'url',
      width: 150,
      title: 'URL',
      sorter: true,
      isViewByDefault: true,
      getValue: data => data.url,
      edit: {
        component: 'Input',
        placeholder: 'Введите',
        maxLength: 255,
        required: true,
      },
    },
    {
      key: 'hidden',
      width: 150,
      title: 'Скрыть',
      sorter: true,
      isViewByDefault: false,
      getValue: data => data.test_bool ? 'Да' : 'Нет',
      edit: {
        component: 'Switch',
      },
    },
  ],
  filters: [],
}

export default config